<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Distribution Rules</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-9">
                <h3 class="mb-0" id="leads_main_heading">Distribution Rules</h3>
              </div>
              <div class="col-3 text-right">
                <el-switch v-model="distribute_leads_evenly" @change="changeDistributionStyle"></el-switch> <span class="text-sm">Distribute Leads Evenly</span>
              </div>
            </div>
          </template>
          <div>
            <el-table
              :data="venue_specialists"
              row-key="id"
              v-loading="loading"
              header-row-class-name="thead-light"
              id="lead_venue_specialists_table"
            >
              <el-table-column
                label="Name"
                prop="name"
                min-width="100px"
                sortable="custom"
              >
                <template v-slot="{ row }">
                  <span class="font-weight-600 name mb-0 text-sm">{{
                    row.name
                  }}</span>
                </template>
              </el-table-column>
  
              <el-table-column
                label="Wedding"
                min-width="70px"
              >
                <template v-slot="{ row }">
                  <base-checkbox v-model="row.event_type_wedding" @input="updateField(row.id, 'event_type_wedding', row.event_type_wedding)" class="ml-3">
                  </base-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                label="Corporate"
                min-width="70px"
              >
                <template v-slot="{ row }">
                  <base-checkbox v-model="row.event_type_corporate" @input="updateField(row.id, 'event_type_corporate', row.event_type_corporate)"  class="ml-3">
                  </base-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                label="Social"
                min-width="70px"
              >
                <template v-slot="{ row }">
                  <base-checkbox v-model="row.event_type_social" @input="updateField(row.id, 'event_type_social', row.event_type_social)" class="ml-3">
                  </base-checkbox>
                </template>
              </el-table-column>
  
              <el-table-column
                label="Lead Cap (/MO)"
                min-width="160px"
              >
                <template v-slot="{ row }">
                  <span  @click.prevent="updateField(row.id, 'max_cap_active', !row.max_cap_active)" id="active_toggle" class="float-left">
                    <base-switch v-model="row.max_cap_active"></base-switch>
                  </span>
                  <el-input-number
                    placeholder=" " v-model="row.max_cap"  @change="updateField(row.id, 'max_cap', row.max_cap)"
                    class="inline-input float-left ml-2"
                    size="mini"
                    resize="horizontal" v-if="row.max_cap_active"  :min="1"></el-input-number>
                </template>
              </el-table-column>
  
              <el-table-column
                label="Active"
                min-width="120px"
              >
                <template v-slot="{ row }">
                  <span @click.prevent="updateField(row.id, 'active', !row.active)" id="toggle_scheduler_access">
                    <base-switch v-model="row.active"></base-switch>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Table, TableColumn, Select, Option, Loading, InputNumber, Input, Switch } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import tableMixin from "@/helpers/remoteTableMixin";
import { LeadService } from "@/services";
import { mapState } from "vuex";

export default {
  mixins: [tableMixin],
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [InputNumber.name]: InputNumber,
    [Switch.name]: Switch,
  },
  data() {
    return {
      venue_specialists: [],
      distribute_leads_evenly: true,
      loading: true,
    };
  },
  created() {
    this.loadPage();
  },
  computed: {
    ...mapState("country/", {
      country: (state) => state.country || "AU",
    }),
  },
  methods: {
    async loadPage() {
      this.loading = true;
      let data = await LeadService.getVenueSpecialists();
      let temp = [];
      data.data.forEach(vc => {
        if (vc.country === this.country) {
          temp.push(vc);
        }
      });
      temp.sort(function(a,b){
        a = a.name;
        b = b.name;
        return (a === b) ? 0 : (a > b) ? 1 : -1;
      });
      this.venue_specialists = temp;

      this.loadSettings();
      this.loading = false;
    },
    async loadSettings() {
      let settings = await LeadService.getVenueSpecialistSetting();
      this.distribute_leads_evenly = (this.country).toLowerCase() == "uk" ? settings.data.distribute_leads_evenly_uk : settings.data.distribute_leads_evenly_au;
    },
    async updateField(id, field, value) {
      this.loading = true;
      let data = await LeadService.changeVenueSpecialistAttribute(id, field, value);
      this.loadPage();
      this.loading = false;
    },
    async changeDistributionStyle() {
      this.loading = true;;
      let attribute = 'distribute_leads_evenly_' + (this.country).toLowerCase();
      let data = await LeadService.changeVenueSpecialistSetting(attribute, this.distribute_leads_evenly);
      this.loadSettings();
      this.loading = false;
    },
  },
};
</script>
  <style>
  .no-border-card .card-footer {
    border-top: 0;
  }
  .el-table .cell {
    word-break: normal;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #f6f9fc !important;
    cursor: pointer;
  }
  .inline-input {
    width: 120px;
    display: inline-block;
  }
  #active_toggle {
    display: inline-block;
  }
  </style>
  